.hero {
  position: relative;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero__media {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero__content {
  position: absolute;
  z-index: 1;
  color: white;
  text-align: center;
  padding: 20px;
  margin: auto;

  h5 {
    margin-top: 12px;
    height: 60px;
    color: var(--lightGray);
  }
}
