.section__title {
  margin-bottom: 80px !important;
  text-align: center;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
}

.item__text {
  text-align: center;
  color: var(--midGray);
}

.item__image {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .section__title {
    margin-bottom: 24px !important;
    text-align: center;
  }
  .item {
    flex-direction: row;
  }
  .item__text {
    text-align: start;
    font-size: 12px !important;
    line-height: 18px !important;
  }
  .item__image {
    display: flex;
    align-items: center;
    margin: auto;
    margin-inline-end: 12px;
    width: 45px;
    height: 45px;
  }
}
