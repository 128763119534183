.highlight__overlay,
.highlight__overlay__more {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(38, 38, 38, 0) 0%, #262626 100%);
  border-radius: 6px;
}
.highlight__overlay__more {
  background: linear-gradient(180deg, rgba(38, 38, 38, 0.20) -9.03%, #262626 100%);
}
.highlight__more {
  cursor: pointer;
  color: var(--lightOrange);
}

.highlight__text {
  position: absolute;
  bottom: 20px;
  left: 10px;
  right: 10px;
  color: white;
  p {
    color: white;
    line-height: 28px !important;
  }
}

.card__content {
  padding-top: 1rem;
}
.publish__date {
  padding: 0.5rem;
  color: #939598;
}

@media (max-width: 768px) {
  .highlight__text {
    p,
    span {
      font-size: 12px;
    }
  }
}
