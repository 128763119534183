.slick-dots {
  bottom: 10px;
}
.slick-next {
  right: -10px;
}
.slick-prev {
  left: -10px;
}

.slick-prev:before,
.slick-next:before {
  color: unset;
  content: unset;
}

.slick-prev,
.slick-next {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background-color: #c4e9d8b2 !important;
  border-radius: 30px !important;
  width: 40px !important;
  height: 40px !important;
  z-index: 999 !important;
  svg {
    color: var(--green) !important;
    font-size: medium;
  }
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: var(--brand1);
  outline: none;
  background: var(--brand1);
  svg {
    color: white;
  }
}

.slick-disabled {
  display: none !important;
}
