.hero {
  position: relative;
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
}

.hero__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero__content {
  position: relative;
  bottom: 45px;
  left: 0;
  width: 100%;
  z-index: 1;
  color: white;
  text-align: start;
  padding: 20px;
  h2 {
    font-weight: 400;
  }
  h4 {
    width: 75%;
    margin-top: 1rem;
    font-weight: 300;
    font-size: 30px;
  }
}

.hero__footer {
  margin-top: 20px;
  display: flex;
  align-items: center;

  img {
    width: 36px;
    margin-inline-end: 12px;
  }
  .hero__footer__author {
    margin-inline-end: 20px;
  }
}

@media (max-width: 768px) {
  .hero {
    height: 500px;
  }

  .hero__footer {
    img {
      width: 12px;
    }
  }

  h4 {
    font-size: 14px !important;
  }
}
