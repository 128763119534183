.logo__box {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #0a142f1a;
  border-radius: 25px;
  height: 50px;
  width: 50px;
}

.footer__text {
  text-align: center;
  margin-top: 24px;
  color: var(--midGray);
  height: 60px;
  font-family: "Lato" !important;
}
