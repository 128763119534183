.card__title {
  font-size: 22px !important;
  font-weight: 600 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  height: 60px;
}

.card__footer {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--midGray);
}

.card__avatar__name {
  display: flex;
  align-items: center;
  img {
    margin-inline-end: 12px;
    width: 36px;
  }
}

@media (max-width: 768px) {
  .card__footer {
    margin-top: 8px;
  }

  .card__title {
    font-size: 12px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
  .card__avatar__name {
    img {
      width: 12px;
      height: 12px;
      margin-inline-end: 4px;
    }
  }
  .card__footer__author,
  .card__footer__publish__date {
    font-size: 8px !important;
    line-height: 8px !important;
  }
  .card__footer__author {
    max-width: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
}
