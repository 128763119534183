.otrovato {
  display: flex;
  align-items: center;
  justify-content: center;
}

.otrovato__background {
  background-image: url("../../../assets/ellipse.png");
  width: 100%;
  height: 100%;
  position: absolute;
  background-repeat: no-repeat;
  transform: translateY(-150px);
  z-index: -9;
}

.title__container {
  display: flex;
  margin-bottom: 24px;
  width: 70%;
  h2 {
    display: inline;
  }
  a.otrovato__link {
    display: inline-flex;
    height: 50px;
  }
  .otrovato__logo {
    height: 50px;
    display: inline;
    margin-inline-start: 6px;
  }
}

.otrovato__description {
  width: 90%;
  color: var(--midGray);
  font-size: 20px !important;
}

a.a__link {
  color: var(--green);
}

@media (max-width: 768px) {
  .otrovato__background {
    background-image: url("../../../assets/ellipse-responsive.png");
    transform: translateY(0px);
  }
  .title__container {
    h2 {
      font-size: 24px !important;
      line-height: 35px;
    }
    .otrovato__logo {
      height: 35px;
    }
  }
  .otrovato__description,
  .title__container {
    width: 100%;
  }
  .otrovato__description {
    font-size: 12px !important;
  }
}
