// Common styles
body {
  margin: 0;
  font-family: "Poppins, Lato";
}

.base-slider > .slick-list > .slick-track > .slick-current > div,
.base-slider > .slick-list > .slick-track > .slick-slide > div {
  border: none;
  margin-inline: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: unset;
  padding: unset !important;
  padding-block: 6px !important;
}

.section {
  padding-block: 80px;
}

.hero__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #220c0c99;
}

ul {
  list-style-type: disc;
  padding-left: 36px;
}
p {
  margin-bottom: 1rem;
}

.scroll__link {
  cursor: pointer;
  margin-inline-end: 40px;
}
.scroll__link:hover {
  p {
    color: #ffc717;
  }
}
.scroll__link__active {
  > p {
    color: #ffc717;
    font-weight: 600 !important;
  }
}

.transparent__text {
  font-size: 150px !important;
  position: absolute;
  top: -80px;
  left: 0;
  right: 0;
  color: transparent;
  text-align: center;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgba(255, 255, 255, 0.1);
}

.card {
  border-radius: 12px !important;
  border: 1px solid var(--secondary-100, #e8e8ea);
}
#you-may-also-read {
  .section {
    padding-block: 0 !important;
    margin-top: 64px;

  }
}

@media (max-width: 512px) {
  .base-slider > .slick-list > .slick-track > .slick-current > div,
  .base-slider > .slick-list > .slick-track > .slick-slide > div {
    margin-inline: unset !important;
  }
}
@media (max-width: 768px) {
  .section {
    padding-block: 40px;
  }
  .transparent__text {
    font-size: 55px !important;
    top: -10px;
  }
}
