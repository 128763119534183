@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --Poppins: "Poppins";
  --Lato: "Lato";
  --black: #000000;
  --gray: #cacaca;
  --midGray: #939598;
  --lightGray: #dcddde;
  --white: #ffffff;
  --baby-blue: #37c1d2;
  --green: #3bb77e;
  --orange: #f36f25;
  --lightOrange: #ffc717;
  --red: #ed2928;
}
